import React, { useCallback, useEffect, useState } from 'react'
// @ts-ignore
// import TimeMe from 'timeme.js'
import { Box, CircularProgress, Grid, ThemeProvider } from '@mui/material'
import {
  LibraryThemeProvider,
  flockTheme,
  TrackedFooter,
} from '@flock/shared-ui'
import { localStore } from '@flock/utils'
import { useQuery } from '@apollo/client'
import {
  Core_SalesforceAccount,
  LandingGetSalesforceAccountDocument,
  Core_SalesforceScenario,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import * as Sentry from '@sentry/gatsby'

import OfferPageWrapper from '../../components/SalesforceOfferPageComponents/OfferPageWrapper'
import {
  useRecordPageDuration,
  useScrollDepthTracker,
} from '../../components/utils'
import { identify, shouldTrack } from '../../utils/analytics'
import SectionLayout from '../../components/SharedComponents/SectionLayout'

import { SalesforceOfferPageData } from '../../components/SalesforceOfferPageComponents/offerPageTypes'
import {
  OfferPageContextProvider,
  OfferPageContextType,
} from '../../components/OfferPageComponents/OfferPageContext'
import OfferPage from '../../components/SalesforceOfferPageComponents/OfferPage'
import ErrorCard from '../../components/SalesforceOfferPageComponents/ErrorCard'
import {
  DEFAULT_SALES_PHONE_NUMBER,
  DEFAULT_SALES_EMAIL,
  DEFAULT_SALES_CALENDLY,
} from '../../constants'
import PreliminaryOfferPage from '../../components/OfferPageComponents/PrelimOfferPageComponents/PreliminaryOfferPage'
import { PrelimOfferPageData } from '../../components/OfferPageComponents/offerPageTypes'

export const Head = () => (
  <>
    <meta name="robots" content="noindex" />
  </>
)

type PropertyEstimateProps = {
  params: {
    account: string
  }
}

function scenarioSort(
  a: Core_SalesforceScenario,
  b: Core_SalesforceScenario
): number {
  // If both indices are 0, they are considered equal
  if (!a.scenarioOrderIndex && !b.scenarioOrderIndex) return 0

  // If one index is 0, it should be considered greater (move to end)
  if (!a.scenarioOrderIndex) {
    return 1
  }
  if (!b.scenarioOrderIndex) {
    return -1
  }

  // Normal comparison for indices
  return a?.scenarioOrderIndex - b?.scenarioOrderIndex
}

const OfferPageV2 = (props: PropertyEstimateProps) => {
  const { params } = props
  let { account: accountId } = params
  useRecordPageDuration()
  useScrollDepthTracker()

  // URL decode accountID
  if (accountId) {
    accountId = decodeURIComponent(accountId).trim()
  }

  // TODO: Implement accountId validation
  const invalidUuid = typeof window !== 'undefined' && false // && !isAccountId(accountId)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedScenario, setSelectedScenario] = useState<number>(0)

  const [pageData, setPageData] = useState<Partial<SalesforceOfferPageData>>({})

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useQuery(LandingGetSalesforceAccountDocument, {
    variables: { input: { accountId } },
    skip: invalidUuid,
  })

  const initializePageData = useCallback(() => {
    if (accountLoading || accountError) return

    const updatedPageData: Partial<SalesforceOfferPageData> = {}

    if (shouldTrack() && accountId && !localStore?.getItem('accountId')) {
      identify({ userId: accountId as string })
      localStore?.setItem('accountId', accountId)
    }

    const salesforceAccount = accountData?.getSalesforceAccount
      ?.salesforceAccount as Core_SalesforceAccount

    updatedPageData.accountId = accountId
    const salesforceScenarios =
      salesforceAccount?.activeOpportunity?.salesforceScenarios.filter(
        (scenario) => scenario?.offerPrice && scenario?.offerPrice > 0
      ) as Core_SalesforceScenario[]

    // Sort scenarios by scenarioOrderIndex
    let sortedScenarios = salesforceScenarios
    if (salesforceScenarios?.length && salesforceScenarios.length > 1) {
      sortedScenarios = [...salesforceScenarios].sort(scenarioSort)
    }
    updatedPageData.scenarios = sortedScenarios

    // Account information
    updatedPageData.fullName = salesforceAccount?.fullName || ''
    updatedPageData.valuationPageName =
      salesforceAccount?.fullName?.split(' ')[0] || ''
    if (salesforceAccount?.valuationPageNameOverride) {
      updatedPageData.valuationPageName =
        salesforceAccount?.valuationPageNameOverride
    }
    updatedPageData.hideNameOnValuationPage =
      salesforceAccount?.hideNameOnValuationPage || false
    updatedPageData.hideStreetViewsOnValuationPage =
      salesforceAccount?.hideStreetViewsOnValuationPage || false
    updatedPageData.showIncomeStatement =
      salesforceAccount?.showIncomeStatement || false

    // Operator information
    updatedPageData.operator = {
      phoneNumber:
        salesforceAccount?.owner?.phoneNumber || DEFAULT_SALES_PHONE_NUMBER,
      email: salesforceAccount?.owner?.email || DEFAULT_SALES_EMAIL,
      calendlyUrl:
        salesforceAccount?.owner?.calendlyLink || DEFAULT_SALES_CALENDLY,
    }

    // Opportunity information

    const date2020 = new Date(2020, 0, 1)
    const offerDate =
      new Date(sortedScenarios?.[selectedScenario]?.offerDate) > date2020
        ? new Date(sortedScenarios[selectedScenario].offerDate)
        : new Date()

    const thirtyDaysInTheFuture = new Date(offerDate.getTime())
    // Add 30 days
    thirtyDaysInTheFuture.setDate(thirtyDaysInTheFuture.getDate() + 30)
    updatedPageData.expirationDate = thirtyDaysInTheFuture
    // Scenario information
    updatedPageData.selectedScenario = selectedScenario
    updatedPageData.setSelectedScenario = setSelectedScenario

    setPageData(updatedPageData)
    setLoading(false)
  }, [accountData, accountLoading, accountError, selectedScenario, accountId])

  const { offerPageContext } = pageData

  useEffect(() => {
    if (!invalidUuid) {
      initializePageData()
    }
  }, [initializePageData, invalidUuid])

  useEffect(() => {
    if (accountError) {
      console.log('account error')
      Sentry.captureException(
        new Error(`Offer page failed to load for account`),
        {
          tags: {
            critical: true,
          },
          extra: {
            accountId,
          },
        }
      )
    }
  }, [accountError])

  if (invalidUuid || accountError) {
    return (
      <ThemeProvider theme={flockTheme}>
        <LibraryThemeProvider>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            id="calendlyPopupRoot"
            sx={{ backgroundColor: 'trustBlue.main', overflow: 'hidden' }}
          >
            <ErrorCard text="Please try refreshing the page or schedule a call with us to go over your estimated valuation." />
          </Box>
        </LibraryThemeProvider>
      </ThemeProvider>
    )
  }

  return (
    <>
      <OfferPageWrapper accountId={accountId}>
        {loading ? (
          <Box
            height="auto"
            pt="50px"
            sx={{ backgroundColor: 'trustBlue.main' }}
          >
            <Box
              width="100%"
              height="100vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <OfferPageContextProvider
            value={offerPageContext as OfferPageContextType}
          >
            {pageData?.scenarios &&
            pageData?.scenarios[selectedScenario]?.offerPrice &&
            pageData?.scenarios[selectedScenario]?.addresses ? (
              <Box
                height="auto"
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <OfferPage pageData={pageData as SalesforceOfferPageData} />
              </Box>
            ) : (
              <Box
                height="auto"
                position="relative"
                id="calendlyPopupRoot"
                sx={{ backgroundColor: 'trustBlue.main' }}
              >
                <PreliminaryOfferPage
                  userId={accountId}
                  pageData={pageData as PrelimOfferPageData}
                />
              </Box>
            )}
          </OfferPageContextProvider>
        )}

        <SectionLayout name="footer" backgroundColor="gray1.main">
          <Grid item xs={12}>
            <TrackedFooter />
          </Grid>
        </SectionLayout>
      </OfferPageWrapper>
    </>
  )
}

export default OfferPageV2
